<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useAddresses } from '/~/composables/addresses'

export default {
  name: 'checkout-address-item',
  components: {
    BasePlaceholder,
    BaseIcon,
    BaseMenu,
    BaseLoader,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { removeAddress, makeAddressDefault } = useAddresses()

    return {
      removeAddress,
      makeAddressDefault,
    }
  },
  data: () => ({
    processing: false,
  }),
  computed: {
    name() {
      return `${this.item.firstName} ${this.item.lastName}`
    },
    address() {
      const { item } = this

      return [
        item.streetAddress,
        item.suburb,
        (item.state ?? '').toUpperCase(),
        item.postcode,
      ].join(' ')
    },
    icon() {
      if (this.selected) {
        return {
          svg: 'plain/check',
          size: 'lg',
        }
      }

      return {
        svg: 'plain/pin-new',
        size: '2xl',
      }
    },
  },
  methods: {
    menuItems(address) {
      return [
        {
          icon: 'plain/edit2',
          content: 'Edit address',
          click: () => {
            this.$router.push({
              hash: `#profile-add-address/${address.id}`,
            })
          },
        },
        {
          icon: 'trash',
          content: 'Delete address',
          hidden: address.default,
          click: async () => {
            this.processing = true
            this.$emit('remove')
            await this.removeAddress(address)
            this.processing = false
          },
        },
        {
          icon: 'plain/check',
          content: 'Set as Default',
          hidden: address.default,
          click: async () => {
            this.processing = true
            await this.makeAddressDefault(address)
            this.processing = false
          },
        },
      ]
    },
  },
}
</script>

<template>
  <div
    class="relative flex min-h-[88px] items-center justify-between rounded-lg px-6 py-[15px] text-default"
    :class="[
      !loading && !processing && 'cursor-pointer',
      selected ? 'bg-emerald-700/20' : 'bg-white',
    ]"
    @click="$emit('click')"
  >
    <div v-if="loading" class="w-full">
      <div class="w-3.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
      <div class="w-1.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
    </div>

    <template v-else>
      <div
        class="mr-5 flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-white"
        :class="processing && 'opacity-50'"
      >
        <base-icon v-bind="icon" size="md" class="text-primary" />
      </div>

      <div class="flex-1" :class="processing && 'opacity-50'">
        <div class="flex items-start">
          <b v-if="item" class="mr-5 leading-6">
            {{ name }}
          </b>
          <b v-else class="mr-5 text-lg leading-6">
            Select or add billing address
          </b>
          <div
            v-if="showMenu && item.default"
            class="inline-flex items-center text-sm font-semibold leading-6 text-fg-success"
          >
            <base-icon svg="plain/check" size="sm" class="mr-2.5" />
            Default
          </div>
        </div>

        <span v-if="item" class="block text-sm leading-6 text-eonx-neutral-600">
          {{ address }}
        </span>
      </div>

      <div
        v-if="showMenu || $slots.menu"
        class="ml-5 flex items-center justify-center"
        :class="processing && 'opacity-50'"
      >
        <base-menu v-if="showMenu" :items="menuItems(item)" position="right">
          <template #trigger>
            <div class="flex">
              <base-icon svg="plain/more" class="text-primary" size="lg" />
            </div>
          </template>
        </base-menu>

        <slot v-else name="menu" />
      </div>
    </template>

    <base-loader v-if="processing" class="absolute inset-0" fullwidth />
  </div>
</template>
